<template>
  <div class="admin">
    <div class="sub-wrap">
      <div class="container">
        <header class="admin-header">
          <h1>Dashboard</h1>
        </header>
        <admin-nav></admin-nav>
        <div class="admin-body">
          <div class="row row-gutter-20">
            <div class="col-12">
              <div class="admin-dashboard-wrap">
                <h3>{{ meta.users }}</h3>
                <h5>Benutzer</h5>
              </div>
            </div>
            <div class="col-12">
              <div class="admin-dashboard-wrap">
                <h3>{{ meta.campaigns }}</h3>
                <h5>{{ $t("global.campaigns") }}</h5>
              </div>
            </div>
            <div class="col-12">
              <div class="admin-dashboard-wrap">
                <h3>{{ meta.devices }}</h3>
                <h5>Displays</h5>
              </div>
            </div>
            <div class="col-12">
              <div class="admin-dashboard-wrap">
                <h3>{{ meta.active_devices }}</h3>
                <h5>Aktive Displays</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>

<script>
import axios from "axios";
import AdminNav from "@/components/admin_nav";

export default {
  name: "dashboard",
  components: {
    AdminNav,
  },
  data() {
    return {
      meta: {},
    };
  },
  methods: {
    get_data() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/admin/dashboard", {
          headers: { Authorization: this.$store.getters.getToken },
        })
        .then((response) => {
          this.meta = response.data;
        });
    },
  },
  mounted() {
    this.get_data();
  },
};
</script>

<style lang="scss">
.admin-dashboard-wrap {
  border: 1px solid #e5e5e5;
  padding: 50px;
  text-align: center;
  background: #fff;
  margin: 10px 0;

  h3 {
    margin: 0;
    font-size: 50px;
    font-weight: 600;
    line-height: 1;
  }

  h5 {
    margin: 20px 0 0 0;
    font-size: 20px;
    font-weight: 600;
  }
}
</style>
